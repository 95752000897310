.menuTables {
  margin-bottom: 15px;
  pointer-events: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.menuTables th {
  font-weight: bolder;
  font-size: medium;
}