.locationCard {
  width: 100%;
  background-color: #c30e23 !important;
  color: white !important;
  margin-bottom: 15px;
}

.locationCardButton {
  color: white !important;
  border-color: white !important;
}

.locationCardButton:hover {
  color: white !important;
  border-color: white !important;
}