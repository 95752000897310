.footer-box {
  padding-top: 25px;
}

.footer-box a {
  text-decoration: none;
  color: white
}

.footer-grid {
  color: #dedede;
}