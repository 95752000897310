@import url('https://fonts.googleapis.com/css2?family=Ma+Shan+Zheng&family=Solitreo&family=Vibur&display=swap');

.firstContainer::before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(26, 26, 26, 0.233);
  background-image: radial-gradient(black 33%, transparent 33%);
  background-size: 3px 3px;
  z-index: 1;
}

.neonLogo {
  text-align: center;
  padding-top: 20px;
  margin: auto;
  user-select: none;
}

.neonLogo b {
  font: 200 8vh "Vibur";
  color: #fee;
  text-shadow: 0 -40px 100px, 0 0 2px, 0 0 1em #ff4444, 0 0 0.5em #ff4444, 0 0 0.1em #ff4444, 0 10px 3px #000;
}

.neonLogo b span {
  animation: blink linear infinite 2s;
}

.neonLogo b span:nth-of-type(3) {
  animation: blink linear infinite 3s;
}

@keyframes blink {
  78% {
    color: inherit;
    text-shadow: inherit;
  }

  79% {
    color: #333;
  }

  80% {

    text-shadow: none;
  }

  81% {
    color: inherit;
    text-shadow: inherit;
  }

  82% {
    color: #333;
    text-shadow: none;
  }

  83% {
    color: inherit;
    text-shadow: inherit;
  }

  92% {
    color: #333;
    text-shadow: none;
  }

  92.5% {
    color: inherit;
    text-shadow: inherit;
  }
}